import React, { Component } from 'react';
import {mConstants} from './common/fconstants';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './css/leader.css'


class Leaderboard extends Component {
    state = { 
        showMe: this.props.show
    } 

    getPlayers = () => {
      var  {leaderboard} = mConstants;

      if(leaderboard != null) {
        var table = []
        table.push( <tr className="head" key={1}>
                  <td>Rank</td><td colSpan="2">Name</td><td>Score</td>
                  <td><img style={{width:"20px"}} 
                      src="../images/fly-1.png"/></td></tr>)

        for (var i=0 ; i < leaderboard.length; i++ ){
          var {name,score,butterflies} = leaderboard[i];
          table.push(<tr key={i+10} className="lrow">
              <td>{i+1}</td>
              <td colSpan="2">{name}</td>
              <td>{score}</td>
              <td>{butterflies}</td></tr>)

        }

        if(table.length ==1 ){
          table.push(<tr key={i} className="lrow">
           <td colSpan="5">Error in getting leaderboard!</td></tr>)
        }
 
      }

      
      return table;  

    } 

    render() { 
        return (<Modal id="leaderModal" show={this.props.show}
        centered
        size="sm">
          <Modal.Header>
            <Modal.Title> <span className="em">Leaderboard <img style={{width:"40px"}} 
                  src="../images/fly-1.png"/></span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <table style={{width:"100%"}}> 
                <tbody>
                    {this.getPlayers()}  
                 </tbody>
              </table> 
          </Modal.Body>
           <Modal.Footer>
            <Button variant="warning" onClick={this.props.handleClose}>
                 <span className="play">Close</span>
            </Button>
          </Modal.Footer>
        </Modal>);
    }
}
 
export default Leaderboard;