import Block from "./Block";
import React,  { ReactDOM}  from "react";
import {handleMatches, gameFinished} from './common/utils'
import {mConstants, uConstants} from './common/fconstants';
import Meter from './Meter';
import './css/block.css';
import './css/anim.css';


import { animateSwap,animateSub,animateSum } from "./common/animUtils";
import { initScore } from "./common/sprite";
import { playSound } from "./common/sound";

class Matrix extends React.Component {
    constructor(props) {
        super(props);
        this.state =  {
            initState:0,
            numrows: this.props.rows,
            numcols: this.props.cols,
            logs:"dasd",
            width:0,
            height:0,
            dimensions: {width:0,height:0},
        };
        this.conWidth = 0;
        this.conHeight = 0;
        this.left = 0;
        this.top =0;
        
        // this.dragItem = React.createRef(); 
        this.ref = React.createRef();    
        this.minDistance = 50;
        this.startPoint={X:-1,Y:-1};
        this.endPoint={X:-1,Y:-1};
        this._selectGrid = null;
        this.gameFinish = this.props.gameFinish;

    } 

    setContainerDim = () => {
        if (this.ref.current) {
            this.conWidth =  this.ref.current.clientWidth;
            this.conHeight =  this.ref.current.clientHeight;
            this.conLeft =  this.ref.current.offsetLeft;
            this.conTop =  this.ref.current.offsetTop;
            console.log(this.conLeft)
        }    
    } 

    componentDidMount() {
        if(this.ref != null){
            if(this.state.initState == 0){
                uConstants.numrows = this.props.rows;
                uConstants.numcols = this.props.cols;
                
                initScore();
                playSound("init");
                uConstants.gameFinish =  this.gameFinish;
                uConstants.ref = this.ref;
                mConstants._moveDropped = 0;

                mConstants.moveProcessing = true;
                
                handleMatches(false);
                
                this.setState((prevState, props)=> ({
                    initState:prevState.initState + 1
                }));

            }
        }
       
        
    }
    
    componentDidUpdate() {
 
        //this.setContainerDim();
    }

    // toggleModal() {
    //     this.setState((prevState,props) => ({
    //         isDialogOpen:!isDialogOpen
    //     }));
    // }

    toggleModal = () =>  {
        this.openDialog();
    }

   
    touchStart = (e,position) => {
        
        if(mConstants.moveProcessing){
            return;
        }

        mConstants._noRedistribute = false;

        let target = null;
        try{
            target = e.touches[0];
        }catch(ex) {
            target = e.nativeEvent;

        }

        let parent = target.target.parentElement 
        console.log("setting parent " + parent);
    
        this._selectGrid = parent;
        console.log(this._selectGrid);
    
        this.startPoint={X:target.clientX,Y:target.clientY}

    }

 

    touchMove = (e,position) => {
        if(this._selectGrid == null){
            return;
        }

        if(mConstants.moveProcessing){
            return;
        }

        let target =null
        try{
            target = e.touches[0];
        }catch(ex) {
            target = e.nativeEvent;

        }
        
        this.endPoint = {X:target.clientX,Y:target.clientY}
    }


    touchEnd = (e,position) => {
        if(mConstants.moveProcessing){
            return;
        }

        console.log(this._selectGrid);
    

        if(this._selectGrid == null){
            return;
        }

        if (!this.startPoint.X || !this.endPoint.X) 
            return

        if (this.startPoint.X == -1 || this.endPoint.X == -1) 
            return

        let deltaX = this.startPoint.X - this.endPoint.X
        let deltaY = this.startPoint.Y - this.endPoint.Y

        this.startPoint.X = 0;
        this.startPoint.Y = 0;
        this.endPoint.X = 0;
        this.endPoint.Y = 0;

        if(Math.abs(deltaX) < 10 && Math.abs(deltaY) < 10) {
           
            return
        }

        
        let move = 0

        if(Math.abs(deltaX) > Math.abs(deltaY)) {
            move = deltaX > this.minDistance? 1:2 
        }else{    
            move = deltaY > this.minDistance? 4:8 
        }    

        if(move > 0 ){
             try{
                var src= this.findSrc();
                var dest= this.findDest(move);
                if(!src || !dest) return;
                mConstants.moveProcessing = true;
                this._selectGrid = null;
                if(move < 4) {
                    playSound("swap");
                    
                    animateSwap(src,dest);                
                }else {
                    playSound("move");
                    if(move > 5){
                        animateSum(src,dest);                
                    }else if (move < 9) {
                        
                        animateSub(src,dest);                
                    }else{
                        mConstants.moveProcessing = false;
                    }
                }
            }catch(e){
                mConstants.moveProcessing = false;
            }
            return;
        }else{
          
            mConstants.moveProcessing = false;
        }
       
 
    }

    
    findSrc =  () =>{
        if (this.ref.current && this._selectGrid) {
            let parent = this._selectGrid;
            let row = parseInt(parent.getAttribute("row"))
            let col = parseInt(parent.getAttribute("col"))
            var block = this.gridBy(row,col);
            return block;
        }    
    }

    openMyDialog = () =>{
        this.props.openMyDialog();
    }
    
    findDest =  (swipeDirection) =>{
        if (this.ref.current && this._selectGrid) {
            let parent = this._selectGrid;
            let row = parseInt(parent.getAttribute("row"))
            let col = parseInt(parent.getAttribute("col"))

            switch(swipeDirection){
                case 1:
                    col = col -1; 
                    break
                case 2:
                    col = col +1; 
                    break
                case 4:
                    row = row -1; 
                    break
                case 8:
                    row = row +1; 
                    break
            }
    
            var block = this.gridBy(row,col);
           
           
            return block;
        }    
    }

    gridBy = (row,col) => {
        if (this.ref.current) {
            
            let table = this.ref.current.childNodes[0];
            
            
            // Get all buttons as a NodeList
            var tds = table.querySelectorAll('td');

            // Convert buttons NodeList to an array
            var tdArr = Array.from(tds);

            var tds = tdArr.filter(td => 
                (row == parseInt(td.getAttribute("row")) && 
                col == parseInt(td.getAttribute("col")))  
                );
            var block = tds[0].childNodes[0];

            return block;
        }
 }

    makeTile = (i,j,count,number) => {
                
        if(count < 28){
            return <td className="Nest noselect"  
                    row={i}
                    col={j}
                    key={"key" + count}
                    index={count}/>
        }else{
            return  <td 
                    className="Nest noselect"  
                    row={i}
                    col={j}
                    key={"key" + count}
                    index={count}>
                        <Block name={number}/>
                    </td>    
        }           

    } 
    

    createTable = () => {
        let table = []
        let count =0
        let vars = [1,2,3,4,6];
        var len = vars.length;
        var prev = 0;
        for (let i=0; i < this.state.numrows; i++){
            let rows = []
            for (let j=0; j < this.state.numcols; j++){ 
                var mind = Math.floor(Math.random()*len);
                mind= (mind == prev)? Math.floor(Math.random()*len): mind  
                var number = vars[mind];
                count++;
                var tile = this.makeTile(i,j,count,number);
                prev = mind
                rows.push(tile)
            } 

            table.push(<tr className="mRow  " key={"tr" + count}>{rows}</tr>)
        } 
        return table
    } 


    moveFly= () => {
        //var butterfly = getButterlyBlock();
       //flyimages(butterfly);
    }

    toggle(e,className) {
        console.log(e)
        e.target.classList.toggle(className);    
    }

    render(){
        return(<>
        
                <div className="mat-container" key={this.props.key}>
                    <table ref={this.ref}  className="Matrix"
                     onTouchMove= {(e) => this.touchMove(e,1)} 
                     onTouchEnd={(e) => this.touchEnd(e,1)}
                     onTouchStart={(e) => this.touchStart(e,1)}
                       onMouseDown={(e) => this.touchStart(e,1)}
                    onMouseUp={(e) => this.touchEnd(e,1)}
                    onMouseMove={(e) => this.touchMove(e,1)} 
                    >
                        <tbody> 
                             {this.createTable()}
                        </tbody>
                    </table>
                </div>
                <div className="bDiv">
                <Meter/>
                </div>
                {/* <div className="logs"> 
                 {this.state.logs}</div>

                 <div id="clones" className="row" style={{width:"100%"}}> 
                    <div id="mChild" className="CBlock col">
                        
                    </div>
                 </div>  */}
                 
                    <div id="sample" style={{display:"none"}}>
                        <Block name={5} /> 
                    </div>
                    {/* <button onClick={gameFinished}>Default</button>  */}
                    {/* <img id="ms" style={{width:"50px",height:"50px",display:"none"}} 
                            src="images/fly-1.png"/>  */}

                    {/* <div id="heart" className="heart" onClick={(e) => this.toggle(e,"is-active") }/> */}

                    <div id="ms" style= {{display:"none"}} className="fly1"/>

                  
                     
               </>
        )
    }


}

export default Matrix;