import React, { Component } from 'react';
import {mConstants} from './common/fconstants';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './css/leader.css'
import { butterflyDown } from './common/utils';
import {fetchPlayer,createLeader,updateLeader} from '../../Data/LeaderboardAPI'

class SaveScore extends Component {
    state = {  

    } 
    entryRef = React.createRef();    

    saveToDB = async (name,email) => {
        var {butterfly,score}  =  mConstants.result;
        var player =  await fetchPlayer(email);
        if(player != null) {
            var {score,butterflies} = player;
            if(butterflies < butterfly){
              await updateLeader(name , email,score,butterfly);
            }

        }else{
          await createLeader(name,email,score,butterfly);
         
        }

        this.props.handleClose();
        
    } 
    
    handleSave = () => {
        var div = this.entryRef.current;
        var nameInput = div.querySelectorAll('input.name')[0]
        var emailInput = div.querySelectorAll('input.email')[0]
        var errorDiv = document.getElementById("error");
        if(nameInput.value.length < 3) {
          errorDiv.textContent = "Please fill the name atleast 3 characters long!"
          
          return 
        }

        if(emailInput.value.length < 3) {
          errorDiv.textContent = "Please fill the email atleast 3 characters long!"
          return
        }

        var name = nameInput.value;
        var email = emailInput.value;
        
        this.saveToDB(name,email);
        //var divs = myComp.querySelectorAll('div.Block');

    }

    render() { 
        return (<Modal id="SaveScoreModal" show={this.props.show}
        centered
        size="sm">
          <Modal.Header>
            <Modal.Title> <span className="em">Save your effort!</span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
                <div ref={this.entryRef} style={{fontSize:"bold",fontSize:"13px",fontFamily:"monospace"}}>
                  Name as to be displayed in Leaderboard: <br/>
                  <input name="name" className='name' placeholder='Name' /> <br/><br/>
                  Email for saving to your account:<br/>
                  <input name="email" className='email' placeholder='Your email' />
                </div> 
          </Modal.Body>
           <Modal.Footer>
            <Button variant="warning" onClick={this.handleSave}>
                 <span className="play">Save</span>
            </Button>
            <Button variant="warning" onClick={this.props.handleClose}>
                 <span className="play">Ignore</span>
            </Button>

            <div id="error" style={{color:"red"}}>

            </div>

          </Modal.Footer>
        </Modal>);
    }
}
 
export default SaveScore;