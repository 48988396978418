import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Row,Col,Button } from 'react-bootstrap';

import Leaderboard from './Leaderboard';
import './css/leader.css'

class StartModal extends Component {
    state = { 
        leadershow:false
       
    } 


    showLeader = () => {
      this.setState({leadershow:true})
    }

    handleLeaderClose = () => {
      this.setState({leadershow:false})
    }

    render() { 
        return (<>
        <Modal id="startModal" show={this.props.show}
        centered
        size="sm">
          <Modal.Header>
            <Modal.Title style={{width:"90%"}}> 
              <Row>
                <Col className='col-9'>
                <span className="em">Catch <img style={{width:"40px"}} 
                  src="../images/fly-1.png"/></span>
                </Col>
                <Col className='col-1' onClick={this.showLeader}>
                    <Button className="btn btn-link leaderBtn"> 
                            <img style={{width:"30px"}} 
                            src="../images/leaderboard-01.png"/> </Button>
                </Col>
              </Row>
            
                  </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <ul className="mlist">
                <li> Pull down a block <span className="em">Add numbers</span>  </li>
                <li> Pull up a block <span className="em">Subtract numbers</span>  </li>
                <li> Match same 3 numbers to break </li>
                <li> Catch <sup> <img style={{width:"20px"}} 
                  src="../images/fly-1.png"/> </sup> bring to bottom</li>
              </ul> 

          </Modal.Body>
           <Modal.Footer>
            <Button variant="warning" onClick={this.props.handleClose}>
                 <span className="play">Play</span>
            </Button>
          </Modal.Footer>
           
        </Modal>
        <Leaderboard show={this.state.leadershow} 
                    handleClose={this.handleLeaderClose}  /> 
       
        </>);
    }
}
 
export default StartModal;