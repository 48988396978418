import React, { Component } from 'react';
import './css/block.css';

class Meter extends Component {
    state = {  } 
    render() { 
        return (
        <div>
            <div className="row bottomContainer">
                <div className="col"></div>
                <div className="col"> 
                    <div id="mjar" className="mJar" >
                        <div className="cap"/>    
                        <div className="jar"> <span id="lblButterfly">0</span></div>
                    </div>
                </div>
                <div className="col">
                    <div className='scoreBox'> 
                    <span className="score align-middle" id="lblScore">0</span> 
                    </div>
                </div>
            </div>
            
            
        </div>
        );
    }
}
 
export default Meter;

