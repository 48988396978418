import anime from 'animejs/lib/anime.es.js';
import Block from '../Block';
import { prepAnimNode } from './animUtils';
import {planet, randIndex,timeForButterFly,isButterFly,generateButterfly} from './cUtils';
import {uConstants,mConstants} from './fconstants';
import {row,col,location,locationA,remove,gridByNest,generateNewTile} from './cUtils';
import { attribute } from './cUtils';
import { addScore, captureFly } from './sprite';
import { playSound } from './sound';


export function simpleXplosionAnimation(batch,callback){
    if (batch.length == 0) { //nothinh left after all
        return;
    }
   
    var total = 0;
   
    var tl = anime.timeline({
        easing: 'easeInOutCirc',
        autoplay:false,
        complete: (anim) => {
            console.log("now finished");
            addScore(total*10);
            callback();
        }
      });

    var count =0;  

     
    playSound("break");
    for (var i=0 ; i < batch.length; i++) {
        var nests = batch[i];
        count++;
        var p = nests.map(a => a.childNodes[0]);
        
        var m = p.map(a => parseInt(attribute(a,"name")));
        var sum = m.reduce((s,a) => s + a,0);
        console.log(sum);
        total = total + sum;

        tl = tl.add({
            targets: p,
            scaleX: [1,0.5,0.2,1.2,0],
            scaleY: [1,0.5,0.2,1.2,0],
            borderRadius: [0,50],
            opacity: ['100%',"0%"],
            complete : (anime) =>{
                var tables = anime.animatables;
                var d = tables.map(a => a.target);
                console.log("breaking ddd====")
               

                d.forEach(a => {
                    if(a.parentNode){
                        a.parentNode.removeChild(a);
                    }
                    a.remove();
                });

            }
        },0) 
    }
    tl.play();
  
    
   
}

export function killTriplets(ref,possibleDup){

    try{
        if(ref != null) {
            console.log("start explosion");
            simpleXplosionAnimation(possibleDup, () => findEmptyNests());
        }
    }catch (e) {
        console.log(e);
    }
}

export function slideDown(emptyNests){
       
    const {numcols} = uConstants;
   
    mConstants.processCols = {};
    var {processCols} = mConstants;
    

    for (var j = 0; j < numcols; j++) {

        if (j in processCols) {
            continue;
        }

        
        var tilesOfCol = emptyNests.filter(a => {
            return col(a)== j
        });
      
        if (tilesOfCol.length < 1) {
            continue;
        }

        var m = tilesOfCol.map(a=> row(a));

        var maxRow = Math.max.apply(Math, m);

        var col1 = j;

        var dict = {"col":col1, "row": maxRow};
        processCols[j] = maxRow;

        
        slideDownAnimation(dict);
       
   }

}

export function slideDownAnimation(dict){

    var col = dict["col"];
    var row = dict["row"] + 1;

    var {ref} = uConstants;
    var {colAnimationProcessing,processCols} = mConstants;
    
    // if(colAnimationProcessing && !isEmpty(colAnimationProcessing)) {
    //     setInterval(() => {
    //         slideDownAnimation(dict);
    //     }, 0);
    //     return;
    // }

    colAnimationProcessing[col] = col;
    
    // if (! (col in processCols) ) { //looks have been removed by tresasure
    //     return;
    // }

    var unsortedTiles = subviews(ref).filter(a => parseInt(a.getAttribute("col")) == col &&  
                    parseInt(a.getAttribute("row")) < row);
                    
    var sorted = unsortedTiles.sort((a1,a2) => 
                parseInt(a1.getAttribute("index")) - parseInt(a2.getAttribute("index")));

    var fallingTiles1 = [];
    var topNonPlanetTilefound = false;

    for (var i = 0; i < sorted.length; i++) {
        var nest1 = sorted[i];
        if (topNonPlanetTilefound) {
            fallingTiles1.push(nest1);
       } else if (nest1.childNodes.length > 0 ) {
            fallingTiles1.push(nest1);
            topNonPlanetTilefound = true;
        }
    }

    var fallingTiles = fallingTiles1.sort((a1,a2) => 
    parseInt(a1.getAttribute("index")) - parseInt(a2.getAttribute("index")));

    var tileCount = fallingTiles.length;
    var copy = {...dict};
  
    tileCount--;
    copy["counter"] =  tileCount;

    copy["fallingTiles"] = fallingTiles;

    animSliding(copy);
    // animSliding(dict);
}

export function animSliding(dict) {
    var copy = {...dict};
  
    var {ref} = uConstants;
    
    var {processCols,colAnimationProcessing} = mConstants;
    var fallingTiles = copy["fallingTiles"];
    var j = copy["counter"];


    if (j < 0  ) { //aded here

        var mycol = copy["col"];
        delete processCols[mycol];

        if (colAnimationProcessing != null) {
            delete colAnimationProcessing[copy["col"]];
        }

        if (Object.keys(processCols).length == 0) {
                findEmptyNests();

        }

        return;
    }

    var firstView = fallingTiles[j];


    var secondView = null;
    var btn1 = null;
    var i = 1;
    var newPosition = [0,0];

    var mycol = copy["col"];

    if ( !(mycol in  processCols)) {
        return;
    }
      
    if (row(firstView) > 0) {
        if (j > 0) {
            secondView = fallingTiles[j - i];
            btn1 = planet(secondView);
            if (btn1 == null) {
                while (btn1 == null) {
                    i++;
                    if (row(firstView) - i < 0) {
                        btn1 = generateNewTile(firstView.col);
                        if (secondView == null) {
                            var point = location(firstView);
                            newPosition = [point.X, -45 / 2];
                        } else {
                            newPosition = locationA(secondView);

                        }
                        newPosition = [newPosition[0], newPosition[1] - 45];

                        break;
                    }

                    if (j < i) {
                        break;
                    }

                    secondView = fallingTiles[j - i];
 
                    btn1 = planet(secondView);


                }
                j = j-i
            }
        }


        if (newPosition[0] == 0) {
            if (secondView == null) {
                var point = location(firstView);
                newPosition[0] = point.X;
                newPosition[1] = -45 / 2;
            } else {
                newPosition = locationA(secondView);
            }

            // if (btn1 != null) {
            //     remove(btn1);
            // }
        }

    }

    if(btn1 == null) {
        copy["counter"] = j - 1;
        //animSliding(dict);
        animSliding(copy);
        return;
    }

    var afterPosition = locationA(firstView);

    
    var deltaX = afterPosition[0] - newPosition[0];
    var deltaY = afterPosition[1] - newPosition[1];
        
    var  k = j;

    var sNode = prepAnimNode(btn1);
    sNode.copy.style["position"] = "absolute"
    sNode.copy.style["top"] = newPosition[1]  +"px";
    sNode.copy.style["left"] = newPosition[0] +"px";
    
    playSound("roll");
    anime({
        targets: sNode.copy,
        duration: 250,
        easing: 'easeInOutSine',
        translateY: deltaY,
        translateX: deltaX,
        rotate: [anime.random(0, 90),-anime.random(0, 90),anime.random(0, 40),0],
        complete : (anime) =>{
            //btn1.remove();
            
            remove(sNode.node);
            firstView.appendChild(sNode.node);
            sNode.node.style["display"] = "block";
            sNode.copy.remove();

            copy["counter"] = k - 1;

            // MyApplication.getInstance().getSoundManager().
            //         playSound(SoundConstants.SOUND_FALL);
            //animSliding(dict)    
            animSliding(copy);
            
        }}
    );

}

export function redistributeChips() {

    const {numcols,numrows} = uConstants;
    var {ref} = uConstants;

     
    for (var j = numrows - 1; j >= 0; j--) {

        var myrow = j;

        var candidateToSlide = subviews(ref).filter(
            a =>  {
                if( row(a) == myrow) {
                    if(planet(a) == null) {
                        var leftKaNest = gridByNest(ref, row(a) - 1, col(a) -1);
                        if (leftKaNest == null || (leftKaNest != null && 
                            planet(leftKaNest) != null )) {
                            return true;
                        } else {
                            var rightKaNest = gridByNest(ref, row(a) - 1,  col(a) + 1);
                            if (rightKaNest == null || (rightKaNest != null 
                                    && planet(rightKaNest) != null)) {
                                return true;
                            }
                        }
                    } 
                }
                return false;
            } 
        )


        if (candidateToSlide.length > 0) {

            var  nestCount = candidateToSlide.length;
            for (var nestCounter = 0; nestCounter < nestCount; nestCounter++) {

                var nest = candidateToSlide[nestCounter];

                var fromNest = gridByNest(ref,row(nest) - 1, col(nest) - 1);

                if (fromNest == null || planet(fromNest) == null) {
                    fromNest = gridByNest(ref,row(nest) - 1, col(nest) + 1);
                    if (fromNest == null ||  planet(fromNest) == null) {
                        fromNest = null;
                    }
                }

                if (fromNest != null) {
                    playSound("roll");
                    slideToFill(nest, fromNest, false, 
                        () => findEmptyNests());
                        return true;
                }

            }

        }
    }

    return false;

}


export function butterFlyDropStack() {
    var topChip = null;
    var {numrows,ref} = uConstants;
    for (var j = 0; j < numrows; j++) {
        var myrow = j;
       

        var topRowChips = subviews(ref).filter( nest => {
            if(row(nest) == myrow) {
                if (planet(nest) != null) {
                    if (row(nest) > 0) {
                        var  upparKaNest = gridByNest(ref,row(nest) - 1, col(nest));
                        if (upparKaNest != null && planet(upparKaNest) != null) {
                            return false;
                        }
                    }
                    if (isButterFly(nest)) {
                        return false;
                    }
                    return true;
                }
            }
        });

        if (topRowChips.length > 0) {
            topChip =  topRowChips[0];
            if (row(topChip) == 0) {
                continue;
            } else {
                break;
            }
        }
    }
    return topChip;

}


export function  isDropPossible() {
    var emptyColumns = findEmptyColumn();


    if (Object.keys(emptyColumns).length < 3) {
        return false;
    }
    return true;
}



export function dropNewButterFLy() {
    var {numrows,ref} = uConstants;
    
    var  highestNest = butterFlyDropStack();
   if(highestNest == null || row(highestNest)  ==0){
        // _touchbeganStarted = false;
        // _isNumberSwapping = false;
        // nextUserTurn(true);
        return;
   }

    var toNest = gridByNest(ref,row(highestNest) - 1, col(highestNest));

    if(toNest == null) {
        // _touchbeganStarted = false;
        // _isNumberSwapping = false;
        // nextUserTurn(true);

        return;
    }

    // MyApplication.getInstance().getSoundManager().
    //         playSound(SoundConstants.SOUND_NEWBUTTERFLY);

    mConstants.droppingButterFly = true

    slideToFill(toNest, null, true, () => {
       //     dropNewButterFLy();
          mConstants.droppingButterFly = false;
          handleMatches(false);
    });

}

export function slideToFill(toNest,fromNest, butter, callback) {

    var newPosition =[0,0];
    var  btn1;
    if (fromNest == null) {
        if (!butter) {
            btn1 = generateNewTile(toNest.col);
        } else {
            btn1 = generateButterfly();
            mConstants._noRedistribute = true;
        }
        var  lp = location(toNest);
        newPosition[0] = lp.X;
        newPosition[1] = 0;
        document.body.appendChild(btn1);
    } else {
         btn1 = planet(fromNest);
         newPosition = locationA(fromNest);
         //remove(btn1);
    }

    var sNode = prepAnimNode(btn1);

    if(!fromNest) {
        sNode.copy.style["position"] = "absolute"
        sNode.copy.style["top"] = newPosition[1]  +"px";
        sNode.copy.style["left"] = newPosition[0] +"px";
        sNode.copy.style["display"] = "block";
        document.body.appendChild(sNode.copy);
   }

    var afterPosition = locationA(toNest);

    var deltaX = afterPosition[0] - newPosition[0];
    var deltaY = afterPosition[1] - newPosition[1];


    //var sNode = this.prepAnimNode(btn1);
    
    //animateFill(sNode,toNest,newPosition,() => callback());
    

    anime({
        targets: sNode.copy,
        duration: 250,
        easing: 'easeInOutSine',
        translateY: deltaY,
        translateX: deltaX,
        rotate: [anime.random(0, 40),-anime.random(0, 40),0],
        complete : (anime) =>{
            sNode.copy.remove();
            //sNode.node.remove();

            toNest.appendChild(btn1);
            btn1.style["display"] = "block";
            callback();

        }}
    );


}


export function findEmptyNests(){
    var {ref} = uConstants;
    console.log("Finding nests!!")
    var emptyNests = allEmptyNests(ref);
    if(emptyNests.length > 0 ){
        slideDown(emptyNests);
    }else{
        checkFlowerDown()
        return;   
    }

}

export function allEmptyNests(ref){
   
    var nests = subviews(ref)
    
    var empty = nests.filter(a => {
                        var include = false;  
                        if (row(a) > 0) {
                            var mrow =  row(a);
                            var mcol = col(a);
                            var upparKaNest = gridByNest(ref,mrow - 1, mcol);
                            if (planet(upparKaNest) != null) {
                                if (planet(a) == null) {
                                    include = true;
                                }
                            }
                        }
                        return include;  
                    }
         )

    var set = new Set(empty);
    
   return Array.from(set);

}

export function butterflyDown() {
    var {numrows} = uConstants;

    var fly = getButterlyBlock();

    if(fly != null){
        if (row(fly) == numrows-1) {
            return true;
        }
    }

    return false;

}

export function  checkFlowerDown() {
    // if (_killingFlower) {
    //     return;
    // }

    var fly = getButterlyBlock();


    if (fly != null) {
        // if (checkTargetReached()) {
        //     _targetCompleted = true;
        // }
        if(butterflyDown()){
            killFlowerTiles(fly);
        }else{
            handleMatches(false);
        }
    } else  {
        handleMatches(false);
    }
    
}

export function killFlowerTiles(nest){

    if(nest != null) {
        //_killingFlower = false;

        //flies.forEach( nest => {
        playSound("fly");
        captureFly(nest, () => handleMatches(false));    
        //})
    }else{
        handleMatches(false);
    }

}

export function handleMatches(test){
    var {ref} = uConstants;
    var {_moveDropped} = mConstants;

    try {
        // if(mConstants.animation){
        //     return;
        // }

       // mConstants.animation = true;

        var alignedTiles = allMatches(ref);

        if (alignedTiles.length > 0) {
             killTriplets(ref,alignedTiles)
            return true;
        } else if (butterflyDown()) {
            checkFlowerDown();
            return true;
        } else if (_moveDropped > 0) {
            if (!test) {
                drop3Planets(0);
            }
            return false;
        } else if (!mConstants._noRedistribute && redistributeChips()) {
            return false;
        } else if (timeForButterFly()) {
                dropNewButterFLy();
        }else{
            removeFaltu();
            mConstants.moveProcessing = false;
        }
    }catch(e) {
        console.log(e);
    }
    return false;
}

export function removeFaltu(){

    var mRoot = document.getElementById("root");
    var divs = mRoot.querySelectorAll('#root >.Block');
    var divArr = Array.from(divs);
    
    if(divArr.length >0){
        console.log(divArr);
        var faltublocks = divArr.map(
            a => {
                    if(a) {
                        console.log(a);
                        if(attribute(a,"name").length > 0) {
                            return true;
                        }
                    }
                    return false;
                });

        faltublocks.forEach( a => remove(a));
    }

}

export  function getButterlyBlock() {
    var {ref} = uConstants;
    var butterFlies = subviews(ref).filter(a => {
        if(planet(a) != null) {
            var node = planet(a); 
            if(node.getAttribute("name") == "Butterfly"){
               return true; 
            }
        }
        return false;
    }) 

    if (butterFlies.length >0 ) {
        return butterFlies[0];
    }
    return  null;
}

export function allMatches(ref){

    var k = 0; 
    var alignedTiles = [];

    for (var i = 0; i < 7; i += 1) { //horizontal

        //List<PlanetNestView> 
        var matchedNestTiles = gridByRow(ref,i);

        var matches = findTriplets(matchedNestTiles, true);
        if (matches.length > 0) {
            matches.forEach( a => {
                alignedTiles.push(a);
                k++;
            } )
        }
    }

    for (var i = 0; i < 5; i += 1) { //vertical

        var matchedNestTiles 
                    = gridByCol(ref,i);

        var matches = findTriplets(matchedNestTiles, false);
        if (matches.length > 0) {
            matches.forEach( a => {
                alignedTiles.push(a);
                k++;
            } )
        }

    }

    return alignedTiles;

}

export function gridByCol(ref,col){
    if (ref.current) {
        
        let table = ref.current.childNodes[0];
        var tds = table.querySelectorAll('td');

        var tdArr = Array.from(tds);

        var tds = tdArr.filter(td => 
            col == parseInt(td.getAttribute("col")));
     

        return tds;
    }
}

export function gridByRow(ref,row){
    if (ref.current) {
        
        let table = ref.current.childNodes[0];
        var tds = table.querySelectorAll('td');

        var tdArr = Array.from(tds);

        var tds = tdArr.filter(td => 
            row == parseInt(td.getAttribute("row")));
        
        return tds;
    }
}


export function findTriplets(tileButtons, row){

    //ArrayList
    var found = false;
    if(tileButtons){

        
        var str = nestValues(tileButtons);
        
        
        const regexp = /(\d)\1{2,}/g;
        // var r = Pattern.compile(pattern);

        const m = [...str.matchAll(regexp)];

        
        var matches = []
        if (m.length >0) {
            found = true;
            for (var i = 0; i< m.length; i++) {
                var match = m[i];
                var first = match[0];
                var s = match.index;
                var e = match.index + first.length;
                var mtiles = tileButtons.slice(s,e);
               
                matches.push(mtiles);
            }
        
        }

        return matches;
    }
}


export function nestValues(v)
{
    var values = v.map(a => {
        if(a && a.textContent){
            return a.textContent;
        }
        return " ";
    }).join("");
    return values;
}

export function subviews(ref){
    if (ref.current) {
        let table = ref.current.childNodes[0];
        var tds = table.querySelectorAll('td');
        var tdArr = Array.from(tds);
        return tdArr;
    }
    return null;
}

export function superView(p) {
    console.log(p)
    if (p instanceof Block){
        console.log(p)
    }
}

export function findEmptyColumn() {
    var {ref,numcols} = uConstants;
    var emptyColumns = {};
    for (var i = 0; i < numcols; i++) {
        var mycol = i;
        
        var array = subviews(ref).filter(a => col(a) == mycol && planet(a) ==null);
        
        if (array.length > 0) {
            emptyColumns[i]= array.length;
        }

    }

    return emptyColumns;

}

export function gameFinished(){
   playSound("win"); 
   uConstants.gameFinish(true);
   mConstants.result = {
                        "score": document.getElementById("lblScore").textContent,
                        "butterfly": document.getElementById("lblButterfly").textContent 
                       }
}


export function drop3Planets(count) {

    var randomColumns = [];

    var emptyColumns = findEmptyColumn();  //;[[self findEmptyColumn] mutableCopy];

    var index;

    if (!isDropPossible()) {
        mConstants._gameCompleted = true;
        gameFinished();
        return;
    }


    var howmany = 2;
    if (count > 0) {
        howmany = count;
    }

    while (randomColumns.length < howmany) {
        var columns = Object.keys(emptyColumns);

        var i = randIndex(columns.length);

        index = columns[i];

        randomColumns.push(index);
        count = emptyColumns[index];
        emptyColumns[index] = count - 1;
        count--;
        if (count < 1) {
            delete emptyColumns[index];
        }
    }

    dropOneChip(randomColumns);


    // if (_gameCompleted) {
    //     nextUserTurn(true);

    // }

}


export function dropOneChip(randomColumns) {
    var {ref,numrows} = uConstants;

    if (randomColumns.length == 0) {
        mConstants._moveDropped = 0;
        handleMatches(false);
        return;
    }

    var colNum = randomColumns[randomColumns.length - 1];

    randomColumns = randomColumns.filter(a => a != colNum);


    var unsortedfallingTiles =  subviews(ref).filter(a => {
                                if(col(a) == colNum) {
                                    if(planet(a) == null) {
                                        if(row(a) == numrows -1) {
                                            return true;
                                        }else{
                                            var neecheKaNest = gridByNest(ref,row(a) + 1, col(a));
                                            if(planet(neecheKaNest) != null ) {
                                                return true;
                                            }
                                        } 
                                    }
                                }
                                return false;
                            });


    if (unsortedfallingTiles.length > 0) {
        var toNest =  unsortedfallingTiles[unsortedfallingTiles.length - 1];
        slideToFill(toNest, null, false, () =>  dropOneChip(randomColumns));

    }
}





