/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlayer = /* GraphQL */ `
  query GetPlayer($email: String!) {
    getPlayer(email: $email) {
      id
      name
      email
      type
      score
      butterflies
      createdAt
      updatedAt
    }
  }
`;
export const listPlayers = /* GraphQL */ `
  query ListPlayers(
    $email: String
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPlayers(
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        email
        type
        score
        butterflies
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sortedLeaders = /* GraphQL */ `
  query SortedLeaders(
    $type: String!
    $butterfliesScore: ModelPlayerPlayersByTypeAndButterfliesAndScoreCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sortedLeaders(
      type: $type
      butterfliesScore: $butterfliesScore
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        type
        score
        butterflies
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
