import anime from 'animejs/lib/anime.es.js';
import { location,remove,planet } from './cUtils';
import { mConstants } from './fconstants';

export function captureFly(butterfly , callback) {
    
    var img = document.getElementById("ms");
    var loc2 = location(document.getElementById("mjar"));
    var loc = location(butterfly);
    var dNode = img.cloneNode(true);
    
    dNode.style["position"] = "absolute"; 
    dNode.style["top"] =  (loc.Y) +  "px"; 
    dNode.style["left"] = (loc.X) + "px"; 
    dNode.style["display"] = "block"; 
    dNode.style["transform"] = "scale(0.25)"; 
    //dNode.style["translate"] = "-50% -50%";
    dNode.style["transformOrigin"] = "0 0 0"; 
    document.body.appendChild(dNode)
    //transformOrigin: '0 0 0'
    console.log(loc2)
    console.log(loc)
    loc = location(butterfly);
    loc2 = location(document.getElementById("mjar"));
   
    var deltaX = (loc2.X - loc.X)*4;//+30;
    var deltaY = (loc2.Y - loc.Y)*4;// +800;

    
    anime({
        targets: dNode,
        duration: 2000,
        easing: 'cubicBezier(.5, .05, .1, .3)',
        translateY: deltaY,
        translateX: deltaX,
        complete : (anime) =>{
            remove(dNode); 
            var bCount = parseInt(document.getElementById("lblButterfly").textContent);
            document.getElementById("lblButterfly").textContent = bCount+1;
            callback();
        },
        begin: (anim) => {
            remove(planet(butterfly))
        }}
    );
}

export function initScore() {
    
    var score = document.getElementById("lblScore");
    
    anime({
        targets: score,
        duration: 100,
        easing: 'linear',
        innerHTML: 0,
        complete : (anime) =>{
        }}
    );
}

export function addScore(nScore) {
    
    if(nScore > 0) {
        mConstants.isScoring = true;
        var score = document.getElementById("lblScore");
        var bCount = parseInt(score.textContent);
        bCount = bCount+nScore;

        if(bCount >0){
            anime({
                targets: score,
                duration: 1000,
                easing: 'easeInOutQuad',
                textContent: bCount,
                round: 1,
                complete : (anime) =>{
                    mConstants.isScoring = false;
                }}
            );
        }
    }
}