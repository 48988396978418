import {subviews,getButterlyBlock } from "./utils";
import { mConstants } from "./fconstants";


export function location(view){

    var sRect = view.getBoundingClientRect();
    return {"X":sRect.left,"Y":sRect.top}
    
}

export function locationA(view){

   var point = location(view);

   return [point.X,point.Y];
    
}

export function row(view){
    return attribute(view,"row")
}

export function col(view){
    return attribute(view,"col")
}

export function attribute(view,name){
    return parseInt(view.getAttribute(name))
}

export function className(view){
    return view.getAttribute("class")
}

export function planet(view){
    return view.childNodes[0]
}

export function remove(view){
    try{
        view.parentNode.removeChild(view)
    }catch(e){}
}

export function setLocation(view,loc){
    view.style["position"] = "absolute"
    view.style["top"] = loc.Y  +"px";
    view.style["left"] = loc.X +"px";
}

export function setLocationA(view,loc){
    view.style["position"] = "absolute"
    view.style["top"] = loc[1]  +"px";
    view.style["left"] = loc[0] +"px";
}

export function gridByNest(ref,row,col){
        
    let table = ref.current.childNodes[0];
    
    var tds = table.querySelectorAll('td');

    // Convert buttons NodeList to an array
    var tdArr = Array.from(tds);

    var tds = tdArr.filter(td => 
        (row == parseInt(td.getAttribute("row")) && 
        col == parseInt(td.getAttribute("col")))  
        );
    return tds[0];

}

export function randomTimeName() {
    return Math.floor(Math.random() * 9) + 1;
}

export function makeSampleCopy(){
    var sampleDiv = document.getElementById("sample");
    var sample = sampleDiv.childNodes[0];
    var clone = sample.cloneNode(true);
    clone.style.display = "block;"
    return clone;
}

export function generateNewTile(col){
    var block =  makeSampleCopy();
    var name = randomTimeName();
    block.textContent = name;
    block.setAttribute("name",name);
    block.setAttribute("class","Block Color" + name);
    
    return block;

}

export function generateButterfly() {
    var block =  makeSampleCopy();
    block.setAttribute("name","Butterfly");
    block.setAttribute("class","Block fly");
    block.textContent = "";
    return block;
}

export function randIndex(length) {
    return Math.floor(Math.random()*length)
} 

export function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

export  function timeForButterFly(){
    if(mConstants.droppingButterFly){
        return false;
    } 

    if(getButterlyBlock() == null){
        return true;
    }
    return false;
}


export  function isButterFly(nest) {
    if(nest.childNodes.length >0 ) {
        return (nest.childNodes[0].getAttribute("name") == "Butterfly")
    }
    return false;
}