import anime from 'animejs/lib/anime.es.js';
import {locationA,remove,generateNewTile} from './cUtils';
import { mConstants } from './fconstants';
import { findEmptyNests, handleMatches } from './utils';
import { playSound } from './sound';


export function prepAnimNode(src){
   
    var sRect;
    var srcClone;

    var srcClass;
 
    srcClass =  src.getAttribute("class");
    srcClone = src.cloneNode(true);
    
    if(src.parentNode.nodeName == "TD") {
        sRect = src.getBoundingClientRect();

        srcClone.style["position"] = "absolute"
        srcClone.style["top"] = sRect.top  +"px";
        srcClone.style["left"] = sRect.left +"px";
        srcClone.setAttribute("class","CBlock " + srcClass)
        document.body.appendChild(srcClone)
    }else{
        sRect = src.getBoundingClientRect();
        srcClone.setAttribute("class","CBlock " + srcClass)
    }

    src.style["display"] = "none";
    
    return {
        'node': src,
        'copy': srcClone,
        'top' : sRect.top,
        'left':  sRect.left
    };

}

export function animateSub(src,dest){

    var sNode = prepAnimNode(src);
    var dNode = prepAnimNode(dest);

    var deltaY = dNode.top - sNode.top;  

    var sNum = parseInt(sNode.node.getAttribute("name"));

    var dNum = parseInt(dNode.node.getAttribute("name"));

    var newNum = dNum - sNum;
    
     
    if(newNum > 0){
        var newClass = "Block Color" + newNum;

        var dParent = dNode.node.parentNode;
        var newNode = sNode.node.cloneNode(true);
        newNode.setAttribute("class",newClass);   
        newNode.setAttribute("name",newNum);
        newNode.textContent =newNum;
        anime({
            targets: sNode.copy,
            duration: 250,
            easing: 'linear',
            //class: [sNodeClass,newClass],
            translateY: deltaY,
            opacity: ['100%','50%','25%','0%'],
            complete : (anime) =>{
                sNode.copy.remove();
                sNode.node.remove();

                dNode.node.style["display"] = "none";
                dNode.node.remove();
                dNode.copy.remove();
                
                newNode.style["display"] = "block";
                dParent.appendChild(newNode);
                mConstants._moveDropped = 2;
          
                findEmptyNests();
            }}
        );
    }else{
        playSound("wrong");
        
        var subAnim=  anime({
            targets: sNode.copy,
            duration: 250,
            easing: 'linear',
            //class: [sNodeClass,newClass],
            translateY: deltaY,
            opacity: ['100%','50%','25%','0%'],
            autoplay:false,
            complete : (anime) =>{
                remove(sNode.copy);
                remove(dNode.copy);
                sNode.node.style["display"] = "block";
                dNode.node.style["display"] = "block";
               
            }}
        );
        subAnim.play();

        subAnim.finished.then(() => {
            subAnim.reverse();
            mConstants.moveProcessing = false;
          })
    }


}

export function animateSum(src,dest){

    var sNode = prepAnimNode(src);
    var dNode = prepAnimNode(dest);

    var deltaY = dNode.top - sNode.top;  

  
    var sNum = parseInt(sNode.node.getAttribute("name"));

    var dNum = parseInt(dNode.node.getAttribute("name"));

    var newNum = sNum + dNum;
    

    if(newNum <= 9){
        var dParent = dNode.node.parentNode;
        var newClass = "Block Color" + newNum;

        var sNodeClass = sNode.node.getAttribute("class");

        var newNode = generateNewTile(1);
        newNode.setAttribute("class",newClass);   
        newNode.setAttribute("name",newNum);
        newNode.textContent =newNum;
    
        remove(sNode.node);
        remove(dNode.node);
        dParent.appendChild(newNode);
        anime({
            targets: sNode.copy,
            duration: 250,
            easing: 'linear',
           // class: [sNodeClass,newClass],
            translateY: deltaY,
            opacity: ['100%','50%','25%','0%'],
            complete : (anime) =>{
                remove(sNode.copy);
                remove(dNode.copy);
                newNode.style["display"] = "block";
                mConstants._moveDropped = 2;
          
                findEmptyNests();

            }}
        );
    }else{
        playSound("wrong");
                    
        var addAnim =  anime({
            targets: sNode.copy,
            duration: 250,
            easing: 'linear',
           // class: [sNodeClass,newClass],
            translateY: deltaY,
            autoplay: false,
            opacity: ['100%','50%','25%','0%'],
            complete : (anime) =>{
                remove(sNode.copy);
                remove(dNode.copy);
                remove(newNode);
                sNode.node.style["display"] = "block";
                dNode.node.style["display"] = "block";
               
            }}
           
        );
        addAnim.play();

        addAnim.finished.then(() => {
            addAnim.reverse();
            mConstants.moveProcessing = false;
        })

    }

}


export function animateSwap(src,dest){

    var sNode = prepAnimNode(src);
    var dNode = prepAnimNode(dest);

    var deltaX = dNode.left - sNode.left;  


    var sParent = sNode.node.parentNode;
    var dParent = dNode.node.parentNode;

    var tl = anime.timeline({
        easing: 'easeInOutCirc',
        autoplay:false,
        complete: (anim) => {
            console.log("swap finished");
            mConstants._moveDropped = 2;
              
            handleMatches(false);
        }
    });
    
    tl.add({
        targets: sNode.copy,
        duration: 250,
        easing: 'easeInOutCirc',
        translateX: deltaX,
        offset:0,
        complete : (anime) =>{
            sNode.copy.remove();
            sNode.node.style["display"] = "block";
            dParent.appendChild(sNode.node);
          
           
        }}).add({
            targets: dNode.copy,
            duration: 150,
            easing: 'easeInOutCirc',
            translateX: -deltaX,
            offset: 10,
            complete : (anime) =>{
                dNode.copy.remove()
                sParent.appendChild(dNode.node);
                dNode.node.style["display"] = "block";
                
            }}
        );

        tl.play();

    // anime({
    //     targets: sNode.copy,
    //     duration: 250,
    //     easing: 'linear',
    //     translateX: deltaX,
    //     complete : (anime) =>{
    //         sNode.copy.remove();
    //         sNode.node.style["display"] = "block";
    //         dParent.appendChild(sNode.node);
          
           
    //     }}
    // );

    // anime({
    //     targets: dNode.copy,
    //     duration: 250,
    //     easing: 'linear',
    //     translateX: -deltaX,
    //     complete : (anime) =>{
    //         dNode.copy.remove()
    //         sParent.appendChild(dNode.node);
    //         dNode.node.style["display"] = "block";
    //         mConstants._moveDropped = 2;
          
    //         handleMatches(false);
    //     }}
    // );

   
}


export function animateFill(sNode,toNest,newPosition,callback) {
    
    var afterPosition = locationA(toNest);

    var deltaX = afterPosition[0] - newPosition[0];
    var deltaY = afterPosition[1] - newPosition[1];


    anime({
        targets: sNode.copy,
        duration: 250,
        easing: 'easeInOutSine',
        translateY: deltaY,
        translateX: deltaX,
        rotate: [anime.random(0, 40),-anime.random(0, 40),0],
        complete : (anime) =>{
            sNode.copy.remove();
            remove(sNode.node);
            toNest.appendChild(sNode.node)
            sNode.node.style["display"] = "block";
            callback();

        }}
    );
}