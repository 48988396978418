import React, {useState} from 'react';
import './App.css';
import Modal from "react-modal";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Menu from './Components/Menu';
import FlyGame from './Views/FlyGame';
import TimeGame from './Views/TimeGame';
import NumberGame from './Views/NumberGame';
import MathPractice from './Views/MathPractice';


import {
  BrowserRouter as Router,
  Routes, Route

} from 'react-router-dom';

import { Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

const firebaseConfig = {
  apiKey: "AIzaSyBwqPMS-WW7RwwXj7QJHan9NF8wSU6erH0",
  authDomain: "numberflies-90406575.firebaseapp.com",
  projectId: "numberflies-90406575",
  storageBucket: "numberflies-90406575.appspot.com",
  messagingSenderId: "108193457000",
  appId: "1:108193457000:web:6f70da3b3b28025616ecdd",
  measurementId: "G-4H8J1GKL3N"
};

Modal.setAppElement("#root");

function App() {

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <div className="App">
        <Router>
          <div className="row heading">
              <div className="col"> <h3> <img style={{width:"40px"}} 
              src="/images/logo.jpg"/> 
              <a href="https://www.edbetter.uk/"><sub>EdBetter</sub></a>    </h3>
              <span className="tagline">Making Learning Fun</span>
              </div>
            
            </div>
            <div className="centerMenu"><Menu/></div>
          

            <Routes>
                  <Route  path="/fly" element={<FlyGame/>} />
                  <Route  path="/time" element={<TimeGame/>} />
                  <Route  path="/math" element={<NumberGame/>} />
                  <Route  path="/mathpractice/:grade"
                       element={<MathPractice/>}/>
                  <Route  path="" element={<NumberGame/>} />
            </Routes>
        </Router>
    </div>
  );
}



export default App;
