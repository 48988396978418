import React, { Component } from 'react';
import Main from '../Components/numberfly/Main';


class FlyGame extends Component {
    state = {  } 
    render() { 
        return (<div  className="container">
             <Main/>
        </div>);
    }
}
 
export default FlyGame;