import beerSound from '../../assets/beer.mp3'
import explosionSound from '../../assets/bigexplosion.mp3'
import moveSound from '../../assets/move.mp3'
import wrongSound from '../../assets/wrong_move.mp3'
import break1Sound from '../../assets/break1.mp3'
import swapSound from '../../assets/swap.mp3'
import rollSound from '../../assets/roll_down.mp3'
import winSound from '../../assets/win.mp3'
import { mConstants } from './fconstants'

export function matchEffect() {
   const map =  {
    "roll":  new Audio(rollSound),
    "fly":  new Audio(beerSound),
    "move":  new Audio(moveSound),
    "wrong":  new Audio(wrongSound),
    "break":  new Audio(break1Sound),
    "explosion":  new Audio(explosionSound),
    "swap": new Audio(swapSound),
    "win": new Audio(winSound)}

    return map;
} 

export function playSound(effectName) {
    var audios = null 
    
    if(mConstants.audios == null) {
        audios = {
            "roll":  new Audio(rollSound),
            "fly":  new Audio(beerSound),
            "move":  new Audio(moveSound),
            "wrong":  new Audio(wrongSound),
            "break":  new Audio(break1Sound),
            "explosion":  new Audio(explosionSound),
            "swap": new Audio(swapSound),
            "win": new Audio(winSound),

        }
        mConstants.audios = audios; 
    }
    if(effectName === "init"){
        return;
    }

    if(!(effectName in mConstants.audios)) {
        var map = matchEffect();
        mConstants.audios[effectName] = map[effectName];
        var audio = matchEffect()[effectName];
        audio.play();
    }else{
        var audio = mConstants.audios[effectName];
        
        audio.play();
    }
}

