import {Component,props} from "react";

class Block extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name : this.props.name,
    }
  }

  getName = () =>{
    return this.name;
  } 

  handleClick = () => {

  }


  render(){
     return (
        <div name={this.props.name} 
          className={"Block Color" + this.props.name } 
          >
              {this.props.name}
        </div>
    );
  }
}

export default Block;