import React, { Component } from 'react';
import { API } from "aws-amplify";
import { sortedLeaders,getPlayer } from "../graphql/queries";
import {
  createPlayer as createPlayer,
  updatePlayer as updatePlayer,
  deleteScore as deletePlayer,
} from "../graphql/mutations";
import { mConstants } from '../Components/numberfly/common/fconstants';


export async function fetchLeaders() {
    const apiData = await API.graphql({ query: sortedLeaders,
      variables: {type: "player", limit: 10,sortDirection:'DESC'} });
    const leadersFromAPI = apiData.data.sortedLeaders.items;
    //setNotes(leadersFromAPI);
    //console.log(leadersFromAPI);
    mConstants.leaderboard = leadersFromAPI;
    return leadersFromAPI;

}

export async function fetchPlayer(email) {
  const apiData = await API.graphql({ 
    query: getPlayer,
    variables: { email: email },
   });
  const leadersFromAPI = apiData.data.getPlayer;
  //setNotes(leadersFromAPI);
  //console.log(leadersFromAPI);
  return leadersFromAPI;

}

export async function createLeader(name,email,score,butterflies) {
    const data = {
      name: name,
      email: email,
      type: "player",
      score: score,
      butterflies: butterflies,
    };
    await API.graphql({
      query: createPlayer,
      variables: { input: data },
    });
    fetchLeaders();
  }

  export async function updateLeader(name,email,score,butterflies) {
    const data = {
      name: name,
      email: email,
      type: "player",
      score: score,
      butterflies: butterflies,
    };
    await API.graphql({
      query: updatePlayer,
      variables: { input: data },
    });
    fetchLeaders();
  }